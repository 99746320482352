import React from 'react';
import { ChevronRight } from 'lucide-react';

interface StartPageProps {
  onStart: () => void;
}

const StartPage: React.FC<StartPageProps> = ({ onStart }) => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-b from-white to-gray-100 text-primary p-8">
      <img src="/images/Instage_Logo_colour.svg" alt="InStage Logo" className="mb-12 w-48" />
      <h1 className="text-5xl font-bold mb-4 text-center">AI-Guided Reflection Service</h1>
      <h2 className="text-2xl mb-10 text-center text-gray-600">Confidential Information - For Educators and Administrators</h2>
      <p className="text-lg mb-8 max-w-2xl text-center">
        This document outlines a strategic pilot program designed for decision-makers in Co-op, Internship and Workplacement Programs. It contains proprietary information regarding an AI-powered reflection service tailored for enhancing student engagement and learning outcomes.
      </p>
      <p className="text-lg mb-12 max-w-2xl text-center">
        Please proceed only if you are directly involved in the decision-making process for educational initiatives within your institution. By clicking the button below, you confirm that you are an authorized representative of your institution.
      </p>
      <button
        onClick={onStart}
        className="bg-gradient-to-r from-primary to-blue-600 text-white px-8 py-3 rounded-full text-xl font-semibold hover:from-blue-600 hover:to-primary transition-all duration-300 shadow-md flex items-center"
      >
        I Confirm and Proceed
        <ChevronRight className="ml-2 w-6 h-6" />
      </button>
    </div>
  );
};

export default StartPage;