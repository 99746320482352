import React from 'react';
import { User, DollarSign, BarChart2, Book, HelpCircle, Award, Shield } from 'lucide-react';
import ServiceHoursCalculator from '../components/ServiceHoursCalculator';

export interface Addendum {
  title: string;
  content: React.ReactNode | ((props: { handleAddendumClick: (event: React.MouseEvent<HTMLElement>, addendumId: string) => void }) => React.ReactNode);
}

export const addendums: Addendum[] = [
  {
    title: "Feedback So Far",
    content: (
      <div className="space-y-8">
        <div className="bg-white rounded-lg shadow-md p-6">
          <h3 className="text-xl font-bold mb-4 text-header flex items-center">
            <User className="w-6 h-6 mr-2 text-primary" />
            Student Feedback Video - August 22th
          </h3>
          <div className="w-full max-w-4xl mx-auto">
            <div className="relative" style={{ paddingBottom: '56.25%' }}>
              <iframe 
                className="absolute top-0 left-0 w-full h-full"
                src="https://www.youtube.com/embed/aIIKbFnazzk" 
                title="Student Feedback"
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    )
  },
  {
    title: "Post-Pilot Pricing Details",
    content: ({ handleAddendumClick }) => (
      <div className="space-y-8">
        <div className="bg-white rounded-lg shadow-md p-6">
          <h3 className="text-xl font-semibold mb-4 text-primary flex items-center">
            <DollarSign className="w-6 h-6 mr-2 text-primary" />
            Flexible Annual Pricing Plans
          </h3>
          <table className="w-full mb-6 border-collapse">
            <thead>
              <tr className="bg-gray-100">
                <th className="text-left p-2 border">Plan</th>
                <th className="text-left p-2 border">Hourly Rate</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="p-2 border">Pay-As-You-Go (0 hours)</td>
                <td className="p-2 border">$19.90/hour</td>
              </tr>
              <tr>
                <td className="p-2 border">Commit 1,000 Hours Annually</td>
                <td className="p-2 border">$16.20/hour</td>
              </tr>
              <tr>
                <td className="p-2 border">Commit 2,500 Hours Annually</td>
                <td className="p-2 border">$15.20/hour</td>
              </tr>
              <tr>
                <td className="p-2 border">Commit 5,000 Hours Annually</td>
                <td className="p-2 border">$13.20/hour</td>
              </tr>
            </tbody>
          </table>

          <p className="text-sm text-gray-600 mb-4">
            *All plans are billed by the second and include live chat support. Committed hours expire at the end of each annual period if unused.
          </p>

          <a 
            href="#service-hours-calculator"
            className="text-blue-600 hover:underline mb-6 inline-block"
            onClick={(e) => handleAddendumClick(e, 'service-hours-calculator')}
          >
            Use our Service Hours Calculator
          </a>

          <h3 className="text-xl font-semibold mb-4 text-primary flex items-center">
            <DollarSign className="w-6 h-6 mr-2 text-primary" />
            Additional Service Fees & Options
          </h3>
          <table className="w-full mb-6 border-collapse">
            <thead>
              <tr className="bg-gray-100">
                <th className="text-left p-2 border">Service</th>
                <th className="text-left p-2 border">Cost</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="p-2 border">Education Account Setup</td>
                <td className="p-2 border">$5,000 (required)</td>
              </tr>
              <tr>
                <td className="p-2 border">Initial Admin On-boarding & Training (5)</td>
                <td className="p-2 border">$7,500 (required for larger plans)</td>
              </tr>
              <tr>
                <td className="p-2 border">SSO Integration Fee</td>
                <td className="p-2 border">$1,000 (optional)</td>
              </tr>
              <tr>
                <td className="p-2 border">LMS Integration Fee (LTI 1.3)</td>
                <td className="p-2 border">$5,000 (optional)</td>
              </tr>
              <tr>
                <td className="p-2 border">Annual Dedicated Support Manager</td>
                <td className="p-2 border">$10,000 (required for larger plans)</td>
              </tr>
              <tr>
                <td className="p-2 border">Additional Admin Onboarding</td>
                <td className="p-2 border">$499 (each, optional)</td>
              </tr>
              <tr>
                <td className="p-2 border">White Glove Course Integration</td>
                <td className="p-2 border">Free for first 5 courses, $299 each thereafter (One-time fee)</td>
              </tr>
            </tbody>
          </table>

          <h3 className="text-xl font-semibold mb-4 text-primary flex items-center">
            <DollarSign className="w-6 h-6 mr-2 text-primary" />
            Term Discounts
          </h3>
          <ul className="list-disc list-inside mb-6">
            <li>2 year - 10% off</li>
            <li>3 year - 15% off</li>
          </ul>
        </div>
      </div>
    )
  },
  {
    title: "Service Hours Calculator",
    content: ({ handleAddendumClick }) => (
      <div className="bg-white rounded-lg shadow-md p-6">
        <h3 className="text-xl font-semibold mb-4 text-primary flex items-center">
          <BarChart2 className="w-6 h-6 mr-2 text-primary" />
          Calculate Your Service Hours
        </h3>
        <ServiceHoursCalculator handleAddendumClick={handleAddendumClick} />
      </div>
    )
  },
  {
    title: "Assignment Creation Details",
    content: (
      <div className="space-y-8">
        <div className="bg-white rounded-lg shadow-md p-6">
          <h3 className="text-xl font-bold mb-4 text-header flex items-center">
            <Book className="w-6 h-6 mr-2 text-primary" />
            Assignment Creation Process
          </h3>
          <p className="mb-4">Our comprehensive assignment creation process ensures a smooth integration of AI-guided reflections into your curriculum:</p>
          
          <h4 className="text-lg font-semibold mb-2 text-primary">White Glove Course Integration</h4>
          <ul className="list-disc list-inside mb-4">
            <li>Personalized setup for each pilot reflection assignment</li>
            <li>Tailored to your specific course objectives and requirements</li>
          </ul>

          <h4 className="text-lg font-semibold mb-2 text-primary">1:1 Onboarding and Support</h4>
          <ul className="list-disc list-inside mb-4">
            <li>Dedicated support manager assigned to your institution</li>
            <li>Guided walkthrough of the assignment creation process</li>
            <li>Ongoing assistance throughout the pilot program</li>
          </ul>

          <h4 className="text-lg font-semibold mb-2 text-primary">Assignment Creation Form</h4>
          <p className="mb-2">Our intuitive assignment creation form allows you to customize various aspects of the reflection assignment, including:</p>
          <ul className="list-disc list-inside mb-4">
            <li>Define Reflection Assignment Name and Description</li>
            <li>Choose Reflection Assignment Due Dates</li>
            <li>Set Budget for Maximum Service Hours</li>
            <li>Choose Automated Report Delivery Options</li>
          </ul>

          <p className="mb-4">
            To get a better understanding of the assignment creation process, please view our sample Assignment Creation Form:
          </p>
          
          <a 
            href="https://in-stage-guided-reflection-assignment-creator-hello16.replit.app/"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block bg-primary text-white px-6 py-2 rounded-full hover:bg-blue-700 transition-colors"
          >
            View Sample Assignment Creation Form
          </a>

          <p className="mt-4">
            Our team will work closely with you to ensure that each assignment is optimized for your course&apos;s specific needs and learning outcomes.
          </p>
        </div>
      </div>
    )
  },
  {
    title: "Student Experience Details",
    content: (
      <div className="space-y-8">
        <div className="bg-white rounded-lg shadow-md p-6">
          <h3 className="text-xl font-bold mb-4 text-header flex items-center">
            <User className="w-6 h-6 mr-2 text-primary" />
            Student Experience Overview
          </h3>
          <div className="w-full max-w-4xl mx-auto mb-6">
            <div className="relative" style={{ paddingBottom: '56.25%' }}>
              <iframe 
                className="absolute top-0 left-0 w-full h-full"
                src="https://www.youtube.com/embed/gtddpIseMfg" 
                title="Student Experience Overview"
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <p className="text-gray-700 mb-4">
            Our AI-guided reflection service provides students with an intuitive and engaging experience. Here&apos;s what students can expect:
          </p>
          <ul className="list-disc list-inside space-y-2 text-gray-700">
            <li>Easy access through single sign-on or LMS integration</li>
            <li>Guided prompts to encourage deeper reflection</li>
            <li>Real-time feedback to improve reflection quality</li>
            <li>Progress tracking and goal setting features</li>
            <li>Mobile-friendly interface for on-the-go reflection</li>
          </ul>
        </div>
      </div>
    )
  },
  {
    title: "Data and Reporting Details",
    content: (
      <div className="space-y-8">
        <div className="bg-white rounded-lg shadow-md p-6">
          <h3 className="text-xl font-bold mb-4 text-header flex items-center">
            <BarChart2 className="w-6 h-6 mr-2 text-primary" />
            Data and Reporting Details
          </h3>
          <p className="mb-4">Our comprehensive data and reporting system ensures that both students and administrators have access to valuable insights from the AI-guided reflection process:</p>

          <h4 className="text-lg font-semibold mb-2 text-primary">1. Immediate Student Summaries</h4>
          <ul className="list-disc list-inside mb-4">
            <li>Students receive an instant summary of their reflection session</li>
            <li>Summaries include key points discussed, main reflections, and planned actions</li>
            <li>Students can review and submit their reflection, ensuring accuracy</li>
          </ul>

          <h4 className="text-lg font-semibold mb-2 text-primary">2. Aggregate Reports for Administrators</h4>
          <ul className="list-disc list-inside mb-4">
            <li>All student reflection summaries are aggregated by assignment due date</li>
            <li>Comprehensive reports are automatically sent to the assigned administrator</li>
            <li>Reports provide valuable insights into student engagement and learning outcomes</li>
          </ul>

          <h4 className="text-lg font-semibold mb-2 text-primary">3. Data Visualization and Analysis</h4>
          <ul className="list-disc list-inside mb-4">
            <li>Interactive dashboards for easy data interpretation</li>
            <li>Trend analysis across multiple reflection sessions</li>
            <li>Customizable reports to focus on specific learning objectives or outcomes</li>
          </ul>

          <p className="mb-4">
            To get a better understanding of our reporting capabilities, please view our sample reports:
          </p>

          <div className="flex space-x-4">
            <a 
              href="https://claude.site/artifacts/de8af2a9-1c2a-4e0a-bfe5-61370fa3acda"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block bg-primary text-white px-4 py-2 rounded-full hover:bg-blue-700 transition-colors"
            >
              View Sample Student Summary
            </a>
            <a 
              href="https://www.app.instage.io/reflection-data-test"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block bg-primary text-white px-4 py-2 rounded-full hover:bg-blue-700 transition-colors"
            >
              View Sample Aggregate Report
            </a>
          </div>

          <p className="mt-4">
            Our data and reporting system is designed to provide actionable insights while maintaining student privacy and data security. It offers a powerful tool for tracking student progress, identifying areas for improvement, and demonstrating the effectiveness of the AI-guided reflection process.
          </p>
        </div>
      </div>
    )
  },
  {
    title: "How to apply for the grant",
    content: (
      <div className="space-y-8">
        <div className="bg-white rounded-lg shadow-md p-6">
          <h3 className="text-xl font-bold mb-4 text-header">
            Get up to 100 hours of complimentary time for pilots starting in 2024.
          </h3>
          
          <h4 className="text-lg font-semibold mb-2 text-primary">Eligibility Criteria:</h4>
          <ul className="list-disc list-inside space-y-2 mb-6">
            <li>Must be a co-op placement or program, internship or workplacement</li>
            <li>Must start in 2024</li>
            <li>Minimum of 20 students doing 3 reflection sessions each</li>
            <li>Admin/Faculty are required to provide feedback on their experience</li>
          </ul>
          
          <h4 className="text-lg font-semibold mb-2 text-primary">Application Process:</h4>
          <ol className="list-decimal list-inside space-y-4 mb-6">
            <li>Identify your target student group for the pilot</li>
            <li>Complete the grant application form</li>
            <li>Submit your application for review</li>
            <li>Await approval and further instructions</li>
          </ol>
          
          <h4 className="text-lg font-semibold mb-2 text-primary">Details needed for the application:</h4>
          <ul className="list-disc list-inside space-y-2 mb-6">
            <li>Course name and code</li>
            <li>Number of students in the course</li>
            <li>Brief description of current reflection practices</li>
            <li>Specific learning outcomes you hope to achieve</li>
          </ul>
          
          <div className="flex justify-center mb-8">
            <button 
              className="inline-block bg-primary text-white px-6 py-2 rounded-full hover:bg-blue-700 transition-colors"
              onClick={() => {
                // Open the popup with the Airtable embed
                const popup = window.open('', 'Grant Application', 'width=600,height=600');
                if (popup) {
                  popup.document.write(`
                    <html>
                      <head>
                        <title>Grant Application</title>
                        <style>
                          body { margin: 0; padding: 0; }
                          iframe { border: none; }
                        </style>
                      </head>
                      <body>
                        <iframe src="https://airtable.com/embed/appLohFHY5J1k7yUp/pagnRlflE8objdR07/form" width="100%" height="100%" frameborder="0"></iframe>
                      </body>
                    </html>
                  `);
                } else {
                  // Handle the case where the popup is blocked
                  alert("The application form popup was blocked. Please allow popups for this site and try again.");
                }
              }}
            >
              Start Your Application
            </button>
          </div>
          
          <p className="text-gray-600">
            To apply for the grant, please fill out our online application form. If you have any questions about the application process, please contact our support team at <a href="mailto:team@instage.io" className="text-blue-600 hover:underline">team@instage.io</a>.
          </p>
        </div>
      </div>
    )
  },
  {
    title: "Frequently Asked Questions",
    content: (
      <div className="space-y-8">
        <div className="bg-white rounded-lg shadow-md p-6">
          <h3 className="text-xl font-bold mb-4 text-header flex items-center">
            <HelpCircle className="w-6 h-6 mr-2 text-primary" />
            Frequently Asked Questions
          </h3>
          <p>FAQ content coming soon.</p>
        </div>
      </div>
    )
  },
  {
    title: "Company Overview",
    content: (
      <div className="space-y-8">
        <div className="bg-white rounded-lg shadow-md p-6">
          <h3 className="text-xl font-bold mb-4 text-header flex items-center">
            <Award className="w-6 h-6 mr-2 text-primary" />
            Company Overview
          </h3>
          <div className="space-y-4">
            <p>InStage is a leading provider of AI-guided reflection services for higher education, founded in Toronto, Canada in 2018.</p>
            
            <h4 className="text-lg font-semibold mb-2 text-primary">Our Experience</h4>
            <ul className="list-disc list-inside space-y-2">
              <li>Over 50,000 simulations completed</li>
              <li>Trusted by and partnered with top Canadian institutions including Queen&apos;s University, Seneca College, University of Toronto, University of British Columbia, George Brown College, Humber College, Achev, Contact North, and Fanshawe College</li>
            </ul>
            
            <h4 className="text-lg font-semibold mb-2 text-primary">Our Focus</h4>
            <p>At InStage, we specialize in deploying cutting-edge AI tools for higher education, aiming to revolutionize reflective learning and enhance educational outcomes through innovative technology.</p>
            
            <h4 className="text-lg font-semibold mb-2 text-primary">Why Choose InStage</h4>
            <ul className="list-disc list-inside space-y-2">
              <li>Proven track record of success with major educational institutions</li>
              <li>Cutting-edge AI technology tailored for educational needs</li>
              <li>Commitment to data privacy and security</li>
              <li>Continuous innovation and improvement based on user feedback</li>
              <li>Dedicated support team to ensure smooth implementation and operation</li>
            </ul>
            
            <div className="mt-6">
              <a 
                href="https://instage.io" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="inline-block bg-primary text-white px-4 py-2 rounded-full hover:bg-blue-700 transition-colors"
              >
                Visit InStage Website
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  },
  {
    title: "Education Grade Security",
    content: () => (
      <div className="space-y-8">
        <div className="bg-white rounded-lg shadow-md p-6">
          <h3 className="text-xl font-bold mb-4 text-header flex items-center">
            <Shield className="w-6 h-6 mr-2 text-primary" />
            Education Grade Security
          </h3>
          <div className="space-y-6">
            <section>
              <h4 className="text-lg font-semibold mb-2 text-primary">Data Storage Location</h4>
              <ul className="list-disc list-inside text-gray-700 space-y-2">
                <li>All data is stored in Canada, specifically in AWS Canada (Central) region.</li>
              </ul>
            </section>

            <section>
              <h4 className="text-lg font-semibold mb-2 text-primary">Data Protection</h4>
              <ul className="list-disc list-inside text-gray-700 space-y-2">
                <li>Strong encryption used for all data, both when stored and during transfer.</li>
              </ul>
            </section>

            <section>
              <h4 className="text-lg font-semibold mb-2 text-primary">User Privacy</h4>
              <ul className="list-disc list-inside text-gray-700 space-y-2">
                <li>Privacy policy available at <a href="https://instage.io/privacy-policy" className="text-blue-600 hover:underline">https://instage.io/privacy-policy</a></li>
                <li>Data collection practices detailed in the policy</li>
              </ul>
            </section>

            <section>
              <h4 className="text-lg font-semibold mb-2 text-primary">Easy Account Management</h4>
              <ul className="list-disc list-inside text-gray-700 space-y-2">
                <li>Supports Single Sign-On (SSO) for login</li>
                <li>Role-Based Access Control (RBAC) for different permission levels</li>
              </ul>
            </section>

            <section>
              <h4 className="text-lg font-semibold mb-2 text-primary">Alignment with Education Standards</h4>
              <ul className="list-disc list-inside text-gray-700 space-y-2">
                <li>Aligns with FERPA requirements</li>
                <li>Designed to meet Canadian education privacy requirements</li>
              </ul>
            </section>
          </div>
        </div>
      </div>
    ),
  },
  {
    title: "Academic Research for Reflection",
    content: (
      <div className="space-y-8">
        <div className="bg-white rounded-lg shadow-md p-6">
          <h3 className="text-xl font-bold mb-4 text-header flex items-center">
            <Book className="w-6 h-6 mr-2 text-primary" />
            Academic Research for Reflection
          </h3>
          <div className="space-y-4">
            <p>Academic research has consistently shown the significant benefits of reflective practice in professional and educational settings:</p>
            
            <h4 className="text-lg font-semibold mb-2 text-primary">1. Accelerates Professional Growth</h4>
            <p>Research indicates that professionals who regularly engage in reflection are more likely to advance in their careers. Reflective practice helps individuals learn from their experiences, leading to more effective decision-making and adaptability in the workplace (Schön, 1983).</p>
            
            <h4 className="text-lg font-semibold mb-2 text-primary">2. Enhances Leadership and Teamwork</h4>
            <p>Reflective individuals tend to develop better leadership and teamwork skills. By regularly reflecting on their interactions and decisions, they become more attuned to their own and others&apos; behaviors, leading to improved collaboration and leadership effectiveness (Brookfield, 1995).</p>
            
            <h4 className="text-lg font-semibold mb-2 text-primary">3. Speeds Up Skill Mastery</h4>
            <p>Reflective practices have been shown to significantly enhance the speed of skill acquisition. By systematically reflecting on their experiences, learners can identify what works, what doesn&apos;t, and adjust their strategies accordingly, leading to faster mastery of skills (Kolb, 1984).</p>
            
            <h4 className="text-lg font-semibold mb-2 text-primary">4. Improves Learning Efficiency</h4>
            <p>Reflection helps learners focus on the most critical aspects of their experiences, leading to more efficient learning processes and quicker development of expertise in their field (Moon, 1999).</p>
            
            <div className="mt-6">
              <p className="text-sm text-gray-600">
                References: Schön, D. A. (1983), Brookfield, S. D. (1995), Kolb, D. A. (1984), Moon, J. A. (1999)
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }
];