import React from 'react';
import { ClipboardList, PenTool, BarChart2, ArrowRight, User, CheckCircle, AlertCircle, Book, DollarSign, Zap, CircleDot, Shield, Expand, Database } from 'lucide-react';

export interface Slide {
  title: string;
  content: React.ReactNode | ((props: { handleAddendumClick: (event: React.MouseEvent<HTMLElement>, addendumId: string) => void }) => React.ReactNode);
}

export const slides: Slide[] = [
  {
    title: "Introducing: An AI Guided Reflection Service",
    content: (
      <div className="space-y-4 sm:space-y-8">
        <div className="bg-white rounded-lg shadow-md p-4 sm:p-6">
          <h2 className="text-xl sm:text-2xl font-extrabold mb-2 sm:mb-4 text-primary flex items-center">
            <CheckCircle className="w-5 h-5 sm:w-6 sm:h-6 mr-2 text-primary" />
            Objectives of this presentation:
          </h2>
          <ol className="list-decimal pl-6 sm:pl-8 space-y-2 sm:space-y-4 text-base sm:text-lg text-text">
            <li>Evaluate the proposed solution to decide if it&apos;s worth a future investment.</li>
            <li>If the value is clear, commit to a pilot (grants available to cover costs).</li>
          </ol>
        </div>
      </div>
    )
  },
  {
    title: "What we've heard about Reflection from faculty & admin staff",
    content: (
      <div className="space-y-4 sm:space-y-8">
        {[
          {
            icon: <AlertCircle className="w-5 h-5 sm:w-6 sm:h-6 mr-2 text-primary flex-shrink-0" />,
            title: "Both admin and faculty want students to do more Reflection, but it's tough to scale these efforts.",
          },
          {
            icon: <AlertCircle className="w-5 h-5 sm:w-6 sm:h-6 mr-2 text-primary flex-shrink-0" />,
            title: "Faculty struggle with reviewing and providing feedback for every student:",
            items: [
              "There's not enough time to handle it all.",
              "They need more graders or TAs to assist.",
              "They're not assigning as many reflection exercises as they'd like to.",
            ],
          },
          {
            icon: <AlertCircle className="w-5 h-5 sm:w-6 sm:h-6 mr-2 text-primary flex-shrink-0" />,
            title: "Reflection seems to work well for only a small percentage of students.",
            items: [
              "Students don't know how to reflect.",
              "Many students don't take it seriously.",
              "They might use tools like ChatGPT or copy from others.",
              "Their answers are often generic and lack depth.",
              "Students think their reflections aren't reviewed and rarely get feedback.",
            ],
          },
          {
            icon: <AlertCircle className="w-5 h-5 sm:w-6 sm:h-6 mr-2 text-primary flex-shrink-0" />,
            title: "Admin and faculty face compliance headaches with reflection:",
            items: [
              "Data entry is time-consuming.",
              "There are audits from government, funding programs, or internal committees to deal with.",
            ],
          },
        ].map((section, index) => (
          <div key={index} className="bg-white rounded-lg shadow-md p-4 sm:p-6">
            <h3 className="text-lg sm:text-xl font-bold mb-2 sm:mb-4 text-primary flex items-start">
              {section.icon}
              <span className="flex-grow">{section.title}</span>
            </h3>
            {section.items && (
              <ul className="list-disc list-inside space-y-1 sm:space-y-2 ml-2 sm:ml-4 text-sm sm:text-base">
                {section.items.map((item, itemIndex) => (
                  <li key={itemIndex}>{item}</li>
                ))}
              </ul>
            )}
          </div>
        ))}
      </div>
    )
  },
  {
    title: "How are you doing Reflection now?",
    content: (
      <div className="space-y-4 sm:space-y-8">
        <div className="bg-white rounded-lg shadow-md p-4 sm:p-6">
          <h3 className="text-lg sm:text-xl font-bold mb-2 sm:mb-4 text-header flex items-center">
            <AlertCircle className="w-5 h-5 sm:w-6 sm:h-6 mr-2 text-primary" />
            Challenges:
          </h3>
          <ul className="list-disc list-inside space-y-1 sm:space-y-2 mb-2 sm:mb-4">
            <li>What issues are you facing with student reflection? (Urgency Level: Low, Medium, High)</li>
          </ul>
        </div>

        <div className="bg-white rounded-lg shadow-md p-4 sm:p-6">
          <h3 className="text-lg sm:text-xl font-bold mb-2 sm:mb-4 text-header flex items-center">
            <DollarSign className="w-5 h-5 sm:w-6 sm:h-6 mr-2 text-primary" />
            Cost Assessment:
          </h3>
          <ul className="list-disc list-inside space-y-1 sm:space-y-2">
            <li>Have you examined the cost of Reflection now? (Cost Level: Low, Medium, High)</li>
          </ul>
        </div>

        <div className="bg-white rounded-lg shadow-md p-4 sm:p-6">
          <h3 className="text-lg sm:text-xl font-bold mb-2 sm:mb-4 text-header flex items-center">
            <ArrowRight className="w-5 h-5 sm:w-6 sm:h-6 mr-2 text-primary" />
            Improving Quality:
          </h3>
          <ul className="list-disc list-inside space-y-1 sm:space-y-2">
            <li>Are you focused on improving the quality of student reflection? (Importance: Low, Medium, High)</li>
          </ul>
        </div>

        <div className="bg-white rounded-lg shadow-md p-4 sm:p-6">
          <h3 className="text-lg sm:text-xl font-bold mb-2 sm:mb-4 text-header flex items-center">
            <Expand className="w-5 h-5 sm:w-6 sm:h-6 mr-2 text-primary" />
            Increasing Capacity:
          </h3>
          <ul className="list-disc list-inside space-y-1 sm:space-y-2">
            <li>Are you focused on increasing the capacity for student reflection? (Importance: Low, Medium, High)</li>
          </ul>
        </div>
      </div>
    )
  },
  {
    title: "Key Features of Proposed Solution",
    content: ({ handleAddendumClick }) => (
      <div className="space-y-4 sm:space-y-8">
        <div className="bg-white rounded-lg shadow-md p-4 sm:p-6">
          <h3 className="text-lg sm:text-xl font-bold mb-2 sm:mb-4 text-header flex items-center">
            <CheckCircle className="w-5 h-5 sm:w-6 sm:h-6 mr-2 text-primary" />
            Scalable Quality Reflection
          </h3>
          <ul className="list-disc list-inside space-y-1 sm:space-y-2 text-sm ml-6">
            <li>Expand your institution&apos;s capacity for student reflection without increasing costs.</li>
            <li>Ensures consistent quality as student numbers grow.</li>
          </ul>
        </div>

        <div className="bg-white rounded-lg shadow-md p-4 sm:p-6">
          <h3 className="text-lg sm:text-xl font-bold mb-2 sm:mb-4 text-header flex items-center">
            <BarChart2 className="w-5 h-5 sm:w-6 sm:h-6 mr-2 text-primary" />
            Individualized, Instant Feedback for Students
          </h3>
          <ul className="list-disc list-inside space-y-1 sm:space-y-2 text-sm ml-6">
            <li>Provide instant feedback to students.</li>
            <li>Includes a summary and action plan.</li>
            <li>AI tracks student progress, building upon previous sessions.</li>
          </ul>
        </div>

        <div className="bg-white rounded-lg shadow-md p-4 sm:p-6">
          <h3 className="text-lg sm:text-xl font-bold mb-2 sm:mb-4 text-header flex items-center">
            <Database className="w-5 h-5 sm:w-6 sm:h-6 mr-2 text-primary" />
            Institutional Aggregate Data
          </h3>
          <ul className="list-disc list-inside space-y-1 sm:space-y-2 text-sm ml-6">
            <li>Flag content for faculty review.</li>
            <li>Easily identify reflection themes, progression & success stories.</li>
            <li>Streamlines data entry processes.</li>
          </ul>
        </div>

        <div className="bg-white rounded-lg shadow-md p-4 sm:p-6">
          <h3 className="text-lg sm:text-xl font-bold mb-2 sm:mb-4 text-header flex items-center">
            <Book className="w-5 h-5 sm:w-6 sm:h-6 mr-2 text-primary" />
            Designed for Higher Ed
          </h3>
          <ul className="list-disc list-inside space-y-1 sm:space-y-2 text-sm ml-6">
            <li>Tailored for Higher Ed needs.</li>
            <li>Integrates with existing systems.</li>
            <li>Ensures data security.</li>
            <li>Supports diverse educational objectives.</li>
          </ul>
        </div>

        <div className="bg-white rounded-lg shadow-md p-4 sm:p-6">
          <h3 className="text-lg sm:text-xl font-bold mb-2 sm:mb-4 text-header flex items-center">
            <Shield className="w-5 h-5 sm:w-6 sm:h-6 mr-2 text-primary" />
            Audit-Ready Compliance
          </h3>
          <ul className="list-disc list-inside space-y-1 sm:space-y-2 text-sm ml-6">
            <li>Ensure compliance with government and funding program requirements.</li>
            <li>Stay prepared for audits with easy reporting on reflection activities.</li>
          </ul>
        </div>
      </div>
    )
  },
  {
    title: "What is AI Guided Reflection?",
    content: (
      <div className="space-y-4 sm:space-y-8">
        <div className="bg-white rounded-lg shadow-md p-4 sm:p-6">
          <div className="w-full max-w-4xl mx-auto mb-4 sm:mb-6 relative">
            <div className="relative" style={{ paddingBottom: '56.25%' }}>
              <iframe 
                className="absolute top-0 left-0 w-full h-full"
                src="https://www.youtube.com/embed/gtddpIseMfg" 
                title="AI Guided Reflection Explanation"
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen
              ></iframe>
            </div>
            <div className="absolute top-2 right-2 bg-red-500 text-white px-2 py-1 rounded-md text-sm font-bold">
              Alpha Preview
            </div>
          </div>
          <p className="text-base sm:text-lg mb-2 sm:mb-4">
            AI Guided Reflection is an innovative approach that combines artificial intelligence with traditional reflection practices to enhance student learning and development.
          </p>
          <ul className="list-disc list-inside space-y-1 sm:space-y-2">
            <li>Personalized AI coach guides students through reflection process</li>
            <li>Real-time feedback and prompts to encourage deeper thinking</li>
            <li>Adapts to individual student needs and learning styles</li>
            <li>Provides structured reflection exercises tailored to specific courses or programs</li>
            <li>Generates comprehensive reports for educators and administrators</li>
          </ul>
        </div>
      </div>
    )
  },
  {
    title: "How AI-Guided Reflection Works",
    content: ({ handleAddendumClick }) => (
      <div className="space-y-4 sm:space-y-8">
        <div className="bg-white rounded-lg shadow-md p-4 sm:p-6">
          <img 
            src="/images/ReflectionFaculty_Student.svg" 
            alt="AI-Guided Reflection Process" 
            className="w-full max-w-4xl mx-auto mb-4"
          />
        </div>

        <div className="bg-white rounded-lg shadow-md p-4 sm:p-6">
          <h3 className="text-lg sm:text-xl font-bold mb-2 sm:mb-4 text-header flex items-center">
            <ClipboardList className="w-5 h-5 sm:w-6 sm:h-6 mr-2 text-primary" />
            Step 1: Create Reflection Assignment
          </h3>
          <div className="flex items-start">
            <div className="flex-1">
              <ul className="list-disc list-inside space-y-1 sm:space-y-2 text-sm ml-6">
                <li>Assign 3-12 reflection sessions and set specific due dates.</li>
                <li>Estimated time to set up: 30 minutes.</li>
                <li>Assignment details are automatically sent to students.</li>
                <li>
                  <a
                    href="https://in-stage-guided-reflection-assignment-creator-hello16.replit.app/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 hover:underline"
                  >
                    Open the AI-Guided Reflection Assignment Creator
                  </a>
                </li>
              </ul>
            </div>
            <div className="ml-4 flex-shrink-0">
              <a 
                href="/images/CompetencySelection.png" 
                target="_blank" 
                rel="noopener noreferrer"
              >
                <img 
                  src="/images/CompetencySelection.png" 
                  alt="Competency Selection" 
                  className="w-24 h-24 object-cover rounded-md cursor-pointer hover:opacity-80 transition-opacity"
                />
              </a>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-md p-4 sm:p-6">
          <h3 className="text-lg sm:text-xl font-bold mb-2 sm:mb-4 text-header flex items-center">
            <PenTool className="w-5 h-5 sm:w-6 sm:h-6 mr-2 text-primary" />
            Step 2: Student Completes Reflection Session(s)
          </h3>
          <ul className="list-disc list-inside space-y-4 sm:space-y-6 text-sm ml-6">
            <li>Each session takes 7-15 minutes and can be completed online at any time.</li>
            <ul className="list-none ml-4 space-y-4 sm:space-y-6">
              <li className="flex items-start">
                <div className="flex-1">
                  <strong>a. Pre-Experience Session:</strong>
                  <ul className="list-disc ml-4 space-y-2 sm:space-y-1">
                    <li>Introduction to AI-Guided Reflection process</li>
                    <li>Discussion expectations and competencies</li>
                    <li>Setting experience-length goals and initial action steps</li>
                  </ul>
                </div>
                <div className="ml-4 flex-shrink-0">
                  <a 
                    href="/images/UserHomePageAug26.png" 
                    target="_blank" 
                    rel="noopener noreferrer"
                  >
                    <img 
                      src="/images/UserHomePageAug26.png" 
                      alt="User Home Page" 
                      className="w-24 h-24 object-cover rounded-md cursor-pointer hover:opacity-80 transition-opacity"
                    />
                  </a>
                </div>
              </li>
              <li className="flex items-start">
                <div className="flex-1">
                  <strong>b. Mid-Experience Session(s):</strong>
                  <ul className="list-disc ml-4 space-y-2 sm:space-y-1">
                    <li>1-10 sessions scheduled throughout the Program</li>
                    <li>Assessment of current satisfaction and progress</li>
                    <li>Follow-up on previous actions and competency development</li>
                    <li>Goal review and setting of next action steps</li>
                  </ul>
                </div>
                <div className="ml-4 flex-shrink-0">
                  <a 
                    href="/images/ActivityViewAug26.png" 
                    target="_blank" 
                    rel="noopener noreferrer"
                  >
                    <img 
                      src="/images/ActivityViewAug26.png" 
                      alt="Activity View" 
                      className="w-24 h-24 object-cover rounded-md cursor-pointer hover:opacity-80 transition-opacity"
                    />
                  </a>
                </div>
              </li>
              <li className="flex items-start">
                <div className="flex-1">
                  <strong>c. Post-Experience Session:</strong>
                  <ul className="list-disc ml-4 space-y-2 sm:space-y-1">
                    <li>Evaluation of overall experience and satisfaction</li>
                    <li>Review of feedback, goals, and competency development</li>
                    <li>Discussion of future career plans and ongoing reflection</li>
                  </ul>
                </div>
                <div className="ml-4 flex-shrink-0">
                  <a 
                    href="/images/UserReportAug26.png" 
                    target="_blank" 
                    rel="noopener noreferrer"
                  >
                    <img 
                      src="/images/UserReportAug26.png" 
                      alt="User Report" 
                      className="w-24 h-24 object-cover rounded-md cursor-pointer hover:opacity-80 transition-opacity"
                    />
                  </a>
                </div>
              </li>
            </ul>
          </ul>
        </div>

        <div className="bg-white rounded-lg shadow-md p-4 sm:p-6">
          <h3 className="text-lg sm:text-xl font-bold mb-2 sm:mb-4 text-header flex items-center">
            <BarChart2 className="w-5 h-5 sm:w-6 sm:h-6 mr-2 text-primary" />
            Step 3: Data Aggregation
          </h3>
          <div className="flex items-start">
            <div className="flex-1">
              <ul className="list-disc list-inside space-y-1 sm:space-y-2 text-sm ml-6">
                <li>Summary data is compiled, highlighting key themes and insights.</li>
                <li>Reports are automatically sent to administrators or integrated directly into the LMS.</li>
                <li>All data is protected with education-grade security measures.</li>
              </ul>
            </div>
            <div className="ml-4 flex-shrink-0">
              <a 
                href="/images/AdminReportAug26.png" 
                target="_blank" 
                rel="noopener noreferrer"
              >
                <img 
                  src="/images/AdminReportAug26.png" 
                  alt="Admin Report" 
                  className="w-24 h-24 object-cover rounded-md cursor-pointer hover:opacity-80 transition-opacity"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  },
  {
    title: "Proposed Pricing Post Pilot",
    content: ({ handleAddendumClick }) => (
      <div className="space-y-4 sm:space-y-8">
        <div className="bg-white rounded-lg shadow-md p-4 sm:p-6">
          <h3 className="text-lg sm:text-xl font-bold mb-2 sm:mb-4 text-primary flex items-center">
            <User className="w-5 h-5 sm:w-6 sm:h-6 mr-2 text-primary" />
            Hire an AI Coach for Guided Reflection
          </h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 sm:gap-6">
            {[
              { name: "David", personality: "Analytical", image: "2DavidPortrait_Background (1).png" },
              { name: "John", personality: "Empathetic", image: "JohnPortrait_Standard_Background (1).png" },
              { name: "Nina", personality: "Motivational", image: "NinaPortrait_Headshot_Background (1).png" },
            ].map((coach) => (
              <div key={coach.name} className="bg-gray-100 rounded-lg shadow-sm overflow-hidden">
                <img 
                  src={`/images/${coach.image}`} 
                  alt={coach.name} 
                  className="w-full h-32 sm:h-48 object-cover"
                />
                <div className="p-3 sm:p-4">
                  <h4 className="font-bold text-base sm:text-lg mb-1">{coach.name}</h4>
                  <p className="text-xs sm:text-sm text-gray-600 mb-1">{coach.personality}</p>
                  <p className="text-xs sm:text-sm text-gray-600 mb-1">AI Guided Reflection</p>
                  <p className="text-xs sm:text-sm font-semibold text-primary">$16.20 CAD / hour</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-md p-4 sm:p-6">
          <h3 className="text-lg sm:text-xl font-bold mb-2 sm:mb-4 text-primary flex items-center">
            <DollarSign className="w-5 h-5 sm:w-6 sm:h-6 mr-2 text-primary" />
            $13.20-$19.90 CAD per hour of service usage
          </h3>
          <ul className="list-disc list-inside space-y-1 sm:space-y-2 ml-2 sm:ml-4 text-sm sm:text-base mb-2 sm:mb-4">
            <li>Billed by the second</li>
            <li>Pay only for what you use—enforce student time-limits per session to prevent overusage</li>
            <li>Commit to more hours for better rates</li>
          </ul>
          <div className="bg-gray-100 p-3 sm:p-4 rounded-lg overflow-x-auto">
            <h4 className="font-semibold mb-2 text-sm sm:text-base">Usage Estimates:</h4>
            <table className="w-full text-xs sm:text-sm">
              <thead>
                <tr className="border-b">
                  <th className="text-left py-2">Scenario</th>
                  <th className="text-left py-2">Details</th>
                  <th className="text-left py-2">Cost</th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-b">
                  <td className="py-2">Small Course</td>
                  <td className="py-2">
                    100 students
                    <br />3 reflections each
                    <br /><span className="text-xs italic">(300 reflections * 12 min = 60 hours)</span>
                  </td>
                  <td className="py-2">
                    60 Service Hours @ $16.20/hour
                    <br />Total: $972
                    <br />Per student: $9.72
                  </td>
                </tr>
                <tr>
                  <td className="py-2">Large Course</td>
                  <td className="py-2">
                    500 students
                    <br />5 reflections each
                    <br /><span className="text-xs italic">(2500 reflections * 12 min = 500 hours)</span>
                  </td>
                  <td className="py-2">
                    500 Service Hours @ $15.20/hour
                    <br />Total: $7,600
                    <br />Per student: $15.20
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="mt-2 sm:mt-4">
            <a
              href="#"
              className="text-sm text-blue-600 hover:underline cursor-pointer inline-block"
              onClick={(e) => handleAddendumClick(e, 'service-hours-calculator')}
            >
              Service Hours Budget Calculator
            </a>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-md p-4 sm:p-6">
          <h3 className="text-lg sm:text-xl font-bold mb-2 sm:mb-4 text-primary flex items-center">
            <DollarSign className="w-5 h-5 sm:w-6 sm:h-6 mr-2 text-primary" />
            One-time account setup and implementation fee: $5K-$15K CAD
          </h3>
        </div>

        <div className="mt-2 sm:mt-4">
          <a
            href="#"
            className="text-sm text-blue-600 hover:underline cursor-pointer inline-block"
            onClick={(e) => handleAddendumClick(e, 'post-pilot-pricing-details')}
          >
            View Full Pricing Details
          </a>
        </div>
      </div>
    )
  },
  {
    title: "How to Pilot AI Guided Reflection",
    content: ({ handleAddendumClick }) => (
      <div className="space-y-4 sm:space-y-8">
        <div className="bg-white rounded-lg shadow-md p-4 sm:p-6">
          <h3 className="text-lg sm:text-xl font-bold mb-2 sm:mb-4 text-header flex items-center">
            <Book className="w-5 h-5 sm:w-6 sm:h-6 mr-2 text-primary" />
            Step 1: Identify Student Groups (Co-op, Work Placement, Internships)
          </h3>
          <ul className="list-disc list-inside space-y-1 sm:space-y-2 text-sm ml-6">
            <li>Focus on groups that need immediate attention in reflective practices.</li>
          </ul>
        </div>

        <div className="bg-white rounded-lg shadow-md p-4 sm:p-6">
          <h3 className="text-lg sm:text-xl font-bold mb-2 sm:mb-4 text-header flex items-center">
            <Zap className="w-5 h-5 sm:w-6 sm:h-6 mr-2 text-primary" />
            Step 2: Launch the Pilot
          </h3>
          <ul className="list-disc list-inside space-y-1 sm:space-y-2 text-sm ml-6">
            <li>Assign the selected group of students to complete a 3-5 session guided reflection.</li>
            <li>Enjoy full support with minimal faculty effort—less than 2 hours needed.</li>
            <li>
              Cost: $5K, or apply for a complimentary 100-hour grant for pilots starting in 2024.{' '}
              <a
                href="#"
                className="text-blue-600 hover:underline"
                onClick={(e) => handleAddendumClick(e, 'how-to-apply-for-the-grant')}
              >
                Grant Application Details
              </a>
            </li>
          </ul>
        </div>

        <div className="bg-white rounded-lg shadow-md p-4 sm:p-6">
          <h3 className="text-lg sm:text-xl font-bold mb-2 sm:mb-4 text-header flex items-center">
            <BarChart2 className="w-5 h-5 sm:w-6 sm:h-6 mr-2 text-primary" />
            Step 3: Review the Results
          </h3>
          <ul className="list-disc list-inside space-y-1 sm:space-y-2 text-sm ml-6">
            <li>Evaluate the pilot and plan the next steps.</li>
          </ul>
        </div>
      </div>
    )
  },
  {
    title: "Additional Resources",
    content: ({ handleAddendumClick }) => (
      <div className="space-y-4 sm:space-y-8">
        <div className="bg-white rounded-lg shadow-md p-4 sm:p-6">
          <h3 className="text-lg sm:text-xl font-bold mb-2 sm:mb-4 text-header flex items-center">
            <AlertCircle className="w-5 h-5 sm:w-6 sm:h-6 mr-2 text-primary" />
            Additional Information:
          </h3>
          <ul className="list-disc list-inside space-y-1 sm:space-y-2">
            {[
              { id: 'feedback-so-far', title: 'Feedback So Far' },
              { id: 'post-pilot-pricing-details', title: 'Pricing Details' },
              { id: 'assignment-creation-details', title: 'Assignment Creation Details' },
              { id: 'student-experience-details', title: 'Student Experience Details' },
              { id: 'data-and-reporting-details', title: 'Data and Reporting Details' },
              { id: 'how-to-apply-for-the-grant', title: 'How to apply for the grant' },
              { id: 'f-a-q', title: 'Frequently Asked Questions' },
              { id: 'company-overview', title: 'InStage Credibility & Track Record' },
              { id: 'education-grade-security', title: 'EDU Grade Security and Compliance' },
              { id: 'service-hours-calculator', title: 'Service Hours Calculator' },
            ].map((item) => (
              <li key={item.id}>
                <a
                  href={`#${item.id}`}
                  className="text-blue-600 hover:underline"
                  onClick={(e) => handleAddendumClick(e, item.id)}
                >
                  {item.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    )
  }
];