import React, { useState, useEffect } from 'react';
import { calculateServiceHours, calculateTotalCost } from '../utils/calculatorUtils';

interface ServiceHoursCalculatorProps {
  handleAddendumClick: (event: React.MouseEvent<HTMLElement>, addendumId: string) => void;
}

const ServiceHoursCalculator: React.FC<ServiceHoursCalculatorProps> = ({ handleAddendumClick }) => {
  const [numStudents, setNumStudents] = useState<number | ''>(0);
  const [numReflections, setNumReflections] = useState<number | ''>(3);
  const [timePerReflection, setTimePerReflection] = useState<number | ''>(12);
  const [costPerHour, setCostPerHour] = useState<number | ''>(16.20);
  const [totalServiceHours, setTotalServiceHours] = useState(0);
  const [totalEstimatedCost, setTotalEstimatedCost] = useState(0);
  const [costPerStudent, setCostPerStudent] = useState(0);

  useEffect(() => {
    if (numStudents && numReflections && timePerReflection && costPerHour) {
      const serviceHours = calculateServiceHours(Number(numStudents), Number(numReflections), Number(timePerReflection));
      const totalCost = calculateTotalCost(serviceHours, Number(costPerHour));
      setTotalServiceHours(serviceHours);
      setTotalEstimatedCost(totalCost);
      setCostPerStudent(Number(numStudents) > 0 ? totalCost / Number(numStudents) : 0);
    }
  }, [numStudents, numReflections, timePerReflection, costPerHour]);

  const handleInputChange = (setter: React.Dispatch<React.SetStateAction<number | ''>>) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value === '' ? '' : Number(e.target.value);
    setter(value);
  };

  const handleInputBlur = (setter: React.Dispatch<React.SetStateAction<number | ''>>, min: number, max: number) => () => {
    setter((prev) => {
      if (prev === '') return min;
      return Math.max(min, Math.min(max, Number(prev)));
    });
  };

  return (
    <div className="space-y-4">
      <p className="text-lg mb-6 text-gray-600">Provide estimates for your course or program:</p>
      <div className="space-y-4">
        <div>
          <label className="font-semibold block">Number of Students:</label>
          <input
            type="number"
            value={numStudents}
            onChange={handleInputChange(setNumStudents)}
            onBlur={handleInputBlur(setNumStudents, 0, Infinity)}
            className="w-full p-2 border border-gray-300 rounded mt-1"
            min="0"
          />
        </div>
        <div>
          <label className="font-semibold block">Number of Reflections:</label>
          <input
            type="number"
            value={numReflections}
            onChange={handleInputChange(setNumReflections)}
            onBlur={handleInputBlur(setNumReflections, 1, 12)}
            className="w-full p-2 border border-gray-300 rounded mt-1"
            min="1"
            max="12"
          />
          <p className="text-sm text-gray-500 mt-1">1-12</p>
        </div>
        <div>
          <label className="font-semibold block">Time per Reflection (minutes):</label>
          <input
            type="number"
            value={timePerReflection}
            onChange={handleInputChange(setTimePerReflection)}
            onBlur={handleInputBlur(setTimePerReflection, 7, 20)}
            className="w-full p-2 border border-gray-300 rounded mt-1"
            min="7"
            max="20"
          />
          <p className="text-sm text-gray-500 mt-1">7-20 minutes</p>
        </div>
        <div>
          <label className="font-semibold block">Cost per Hour (CAD):</label>
          <input
            type="number"
            value={costPerHour}
            onChange={handleInputChange(setCostPerHour)}
            onBlur={handleInputBlur(setCostPerHour, 13.20, 19.20)}
            className="w-full p-2 border border-gray-300 rounded mt-1"
            min="13.20"
            max="19.20"
            step="0.01"
          />
          <p className="text-sm text-gray-500 mt-1">$13.20 - $19.20</p>
        </div>
      </div>
      <div className="mt-6 p-4 bg-gray-100 rounded">
        <p className="font-semibold">Total Service Hours: <span className="text-primary">{totalServiceHours.toFixed(2)}</span></p>
        <p className="font-semibold">Estimated Cost: <span className="text-primary">${totalEstimatedCost.toFixed(2)} CAD</span></p>
        <p className="font-semibold">Estimated Service Cost Per Student: <span className="text-primary">${costPerStudent.toFixed(2)} CAD</span></p>
        <p className="text-sm text-gray-500 mt-2">Final cost will depend on volume discount rate per hour</p>
      </div>
      <div className="mt-4">
        <a
          href="#post-pilot-pricing-details"
          className="text-blue-600 hover:underline"
          onClick={(e) => handleAddendumClick(e, 'post-pilot-pricing-details')}
        >
          View Full Pricing Details
        </a>
      </div>
    </div>
  );
};

export default ServiceHoursCalculator;