export function calculateServiceHours(
  numStudents: number,
  numReflections: number,
  timePerReflection: number
): number {
  const totalMinutes = numStudents * numReflections * timePerReflection;
  const serviceHours = totalMinutes / 60; // Convert minutes to hours
  return Math.round(serviceHours * 100) / 100; // Round to 2 decimal places
}

export function calculateTotalCost(serviceHours: number, costPerHour: number): number {
  const totalCost = serviceHours * costPerHour;
  return Math.round(totalCost * 100) / 100; // Round to 2 decimal places
}