import React from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

interface StickyNavigationProps {
  currentSlide: number;
  totalSlides: number;
  onPrevious: () => void;
  onNext: () => void;
  isAddendumSelected: boolean;
}

const StickyNavigation: React.FC<StickyNavigationProps> = ({
  currentSlide,
  totalSlides,
  onPrevious,
  onNext,
  isAddendumSelected,
}) => {
  if (isAddendumSelected) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 p-4">
      <div className="flex items-center justify-between max-w-4xl mx-auto">
        <img src="/images/Instage_Logo_colour.svg" alt="InStage Logo" className="h-6 sm:h-8" />
        <div className="flex items-center space-x-4">
          <button
            onClick={onPrevious}
            disabled={currentSlide === 0}
            className="bg-gray-200 text-primary px-4 py-2 rounded-full flex items-center disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-300 transition-colors text-sm sm:text-base"
          >
            <ChevronLeft className="w-4 h-4 sm:w-5 sm:h-5 mr-1 sm:mr-2" />
            Previous
          </button>
          <span className="text-sm sm:text-base font-semibold text-primary">
            {currentSlide + 1} / {totalSlides}
          </span>
          <button
            onClick={onNext}
            disabled={currentSlide === totalSlides - 1}
            className="bg-primary text-white px-4 py-2 rounded-full flex items-center disabled:opacity-50 disabled:cursor-not-allowed hover:bg-blue-700 transition-colors text-sm sm:text-base"
          >
            Next
            <ChevronRight className="w-4 h-4 sm:w-5 sm:h-5 ml-1 sm:ml-2" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default StickyNavigation;