import React from 'react';
import { Addendum } from '../../data/addendums';

interface AddendumDisplayProps {
  addendum: Addendum;
  onBack: () => void;
  handleAddendumClick: (event: React.MouseEvent<HTMLElement>, addendumId: string) => void;
}

const AddendumDisplay: React.FC<AddendumDisplayProps> = ({ addendum, onBack, handleAddendumClick }) => {
  const renderContent = () => {
    if (typeof addendum.content === 'function') {
      return addendum.content({ handleAddendumClick });
    }
    return addendum.content;
  };

  return (
    <div className="space-y-4">
      {renderContent()}
      <button 
        onClick={onBack} 
        className="mt-6 px-6 py-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition-colors"
      >
        Back to Slides
      </button>
    </div>
  );
};

export default AddendumDisplay;