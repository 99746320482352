import React from 'react';
import AIGuidedReflectionPresentation from './components/AIGuidedReflectionPresentation/index';
import './styles/tailwind.css';

function App() {
  return (
    <div className="App">
      <AIGuidedReflectionPresentation />
    </div>
  );
}

export default App;