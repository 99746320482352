import React from 'react';
import { Slide } from '../../data/slides';

interface SlideContentProps {
  slide: Slide;
  handleAddendumClick: (event: React.MouseEvent<HTMLElement>, addendumId: string) => void;
}

const SlideContent: React.FC<SlideContentProps> = ({ slide, handleAddendumClick }) => {
  return (
    <>
      {typeof slide.content === 'function'
        ? slide.content({ handleAddendumClick })
        : slide.content}
    </>
  );
};

export default SlideContent;