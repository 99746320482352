import React, { useState, useEffect } from 'react';
import { slides } from '../../data/slides';
import { addendums, Addendum } from '../../data/addendums';
import SlideContent from './SlideContent';
import AddendumDisplay from './AddendumDisplay';
import StickyNavigation from './StickyNavigation';
import StartPage from './StartPage';

const AIGuidedReflectionPresentation: React.FC = () => {
  const [currentSlide, setCurrentSlide] = useState(-1); // -1 represents the start page
  const [selectedAddendum, setSelectedAddendum] = useState<string | null>(null);

  const handleAddendumClick = (event: React.MouseEvent<HTMLElement>, addendumId: string) => {
    event.preventDefault();
    setSelectedAddendum(addendumId);
  };

  const goToPreviousSlide = () => {
    setCurrentSlide((prev) => Math.max(0, prev - 1));
  };

  const goToNextSlide = () => {
    setCurrentSlide((prev) => Math.min(slides.length - 1, prev + 1));
  };

  const handleBackFromAddendum = () => {
    setSelectedAddendum(null);
  };

  const handleStart = () => {
    setCurrentSlide(0);
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowLeft') {
        if (selectedAddendum) {
          handleBackFromAddendum();
        } else {
          goToPreviousSlide();
        }
      } else if (event.key === 'ArrowRight' && !selectedAddendum) {
        goToNextSlide();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedAddendum]);

  const renderContent = () => {
    if (currentSlide === -1) {
      return <StartPage onStart={handleStart} />;
    }

    if (selectedAddendum) {
      const addendum = addendums.find((a: Addendum) => a.title.toLowerCase().replace(/\s+/g, '-') === selectedAddendum);
      return addendum ? (
        <AddendumDisplay 
          addendum={addendum} 
          onBack={handleBackFromAddendum} 
          handleAddendumClick={handleAddendumClick}
        />
      ) : null;
    }

    if (currentSlide === slides.length - 1) {
      // This is the Additional Resources slide
      return (
        <>
          <h3 className="text-xl font-bold mb-4 text-header">Additional Information:</h3>
          <ul className="list-disc list-inside space-y-2">
            {addendums.map((addendum: Addendum, index: number) => (
              <li key={index}>
                <a
                  href={`#addendum-${addendum.title.toLowerCase().replace(/\s+/g, '-')}`}
                  className="text-blue-600 hover:underline"
                  onClick={(e) => handleAddendumClick(e, addendum.title.toLowerCase().replace(/\s+/g, '-'))}
                >
                  {addendum.title}
                </a>
              </li>
            ))}
          </ul>
        </>
      );
    }

    return (
      <SlideContent
        slide={slides[currentSlide]}
        handleAddendumClick={handleAddendumClick}
      />
    );
  };

  const selectedAddendumContent = addendums.find((a: Addendum) => a.title.toLowerCase().replace(/\s+/g, '-') === selectedAddendum);

  return (
    <div className="flex flex-col min-h-screen bg-offwhite text-primary">
      <div className="flex-grow w-full max-w-4xl mx-auto px-4 sm:px-6 py-6 sm:py-12 mb-16">
        {currentSlide !== -1 && (
          <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold mb-6 sm:mb-12 text-center text-primary">
            {selectedAddendum ? selectedAddendumContent?.title : slides[currentSlide].title}
          </h1>
        )}
        <div className={`mb-6 sm:mb-12 ${currentSlide !== -1 ? 'bg-white rounded-lg shadow-lg p-4 sm:p-8' : ''}`}>
          {renderContent()}
        </div>
      </div>
      {currentSlide !== -1 && (
        <StickyNavigation
          currentSlide={currentSlide}
          totalSlides={slides.length}
          onPrevious={goToPreviousSlide}
          onNext={goToNextSlide}
          isAddendumSelected={!!selectedAddendum}
        />
      )}
    </div>
  );
};

export default AIGuidedReflectionPresentation;